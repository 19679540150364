<template>
  <router-header :routerHeaderInfo="routerHeaderInfo" />
  <customer-group></customer-group>
</template>

<script>
import { defineComponent, reactive } from "vue";
import _ from "lodash";
import CustomerGroup from "@/views/customer/customerGroup/CustomerGroup";
import RouterHeader from "@/components/RouterHeader";
import { useRoute } from "vue-router";

export default defineComponent({
  name: "MessageShareIndex",

  components: {
    CustomerGroup,
    RouterHeader,
  },
  setup() {
    const route = new useRoute();
    const { roomId, setId } = route.query;
    const routerHeaderInfo = reactive([
      {
        path: "/marketingUtil/liveOpenClassStatistic",
        name: "直播详情",
        query: {
          id: roomId,
        },
      },
      { name: "群发记录" },
    ]);

    if (Number(setId)) {
      _.assign(routerHeaderInfo[0], {
        path: "/marketingUtil/liveColumnStatistic",
        name: "专栏详情",
        query: {
          setId,
        },
      });
    }

    return {
      routerHeaderInfo,
    };
  },
});
</script>
<style lang='less' scoped>
</style>